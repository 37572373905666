<section class="main-default-news-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <div class="single-main-default-news ">
          <a routerLink="/{{Articles[1].slug_url}}">
            <img src="{{ Articles[1].image}}" alt="image" class="rounded img-fluid" width="250px" height="250px">
          </a>
          <div class="news-content">
            <div class="tag">{{ Articles[1].categorie}}</div>
            <h3>
              <a routerLink="/{{Articles[1].slug_url}}">{{ Articles[1].titre}}</a>
            </h3>
            <span>{{ Articles[1].date}}</span>
          </div>
        </div>
        <div class="single-main-default-news">
          <a routerLink="/{{Articles[2].slug_url}}">
            <img src="{{ Articles[2].image}}" alt="image" class="rounded img-fluid" width="250px" height="250px">
          </a>
          <div class="news-content">
            <div class="tag">{{ Articles[2].categorie}}</div>
            <h3>
              <a routerLink="/{{Articles[2].slug_url}}">{{ Articles[2].titre}}</a>
            </h3>
            <span>{{ Articles[2].date}}</span>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="single-main-default-news-inner">
          <a routerLink="/{{Articles[0].titre}}">
            <img src="{{ Articles[0].image}}" alt="image" class="rounded img-fluid" width="100%">
          </a>
          <div class="news-content">
            <div class="tag">{{ Articles[0].categorie}}</div>
            <h3>
              <a routerLink="/{{Articles[0].slug_url}}">{{ Articles[0].titre}}</a>
            </h3>
            <span>{{ Articles[0].date}}</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="single-main-default-news">
          <a routerLink="/{{Articles[3].slug_url}}">
            <img src="{{ Articles[3].image}}" alt="image" class="rounded img-fluid" width="250px" height="250px">
          </a>
          <div class="news-content">
            <div class="tag">{{ Articles[3].categorie}}</div>
            <h3>
              <a routerLink="/{{Articles[3].slug_url}}">{{ Articles[3].image}}</a>
            </h3>
            <span>{{ Articles[3].date}}</span>
          </div>
        </div>
        <div class="single-main-default-news">
          <a routerLink="/{{Articles[4].slug_url}}">
            <img src="{{ Articles[4].image}}" alt="image" class="rounded img-fluid" width="250px" height="250px">
          </a>
          <div class="news-content">
            <div class="tag">{{ Articles[4].categorie}}</div>
            <h3>
              <a routerLink="/{{Articles[4].slug_url}}">{{ Articles[4].image}}</a>
            </h3>
            <span>{{ Articles[4].image}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
