import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './layout/home/home.component';
import { ErreurComponent } from './layout/erreur/erreur.component';
import { FooterComponent } from './layout/section/footer/footer.component';
import { MenuComponent } from './layout/section/menu/menu.component';
import { SidebarComponent } from './layout/section/sidebar/sidebar.component';
import { HomeListComponent } from './layout/section/home-list/home-list.component';
import { HomeRecentComponent } from './layout/section/home-recent/home-recent.component';
import {HttpClientModule} from '@angular/common/http';
import { SingleArticleComponent } from './layout/single-article/single-article.component';
import { CategorieComponent } from './layout/categorie/categorie.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ErreurComponent,
    FooterComponent,
    MenuComponent,
    SidebarComponent,
    HomeListComponent,
    HomeRecentComponent,
    SingleArticleComponent,
    CategorieComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
