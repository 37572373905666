<div class="top-header-area">
  <div class="container">
    <div class="row align-items-center">
      <!--      <div class="col-lg-6">-->
      <!--        <ul class="top-header-social">-->
      <!--          <li>-->
      <!--            <a href="#" class="facebook" target="_blank">-->
      <!--              <i class='bx bxl-facebook'></i>-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#" class="pinterest" target="_blank">-->
      <!--              <i class='bx bxl-instagram'></i>-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#" class="pinterest" target="_blank">-->
      <!--              <i class='bx bxl-linkedin'></i>-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#" class="twitter" target="_blank">-->
      <!--              <i class='bx bxl-twitter'></i>-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a href="#" class="linkedin" target="_blank">-->
      <!--              <i class='bx bxl-youtube'></i>-->
      <!--            </a>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </div>-->
      <!--      <div class="col-lg-6">-->
      <!--        <ul class="top-header-others">-->
      <!--          <li>-->
      <!--            <div class="languages-list">-->
      <!--              <select>-->
      <!--                <option value="1">English</option>-->
      <!--                <option value="2">العربيّة</option>-->
      <!--                <option value="3">Deutsch</option>-->
      <!--                <option value="3">Português</option>-->
      <!--                <option value="3">简体中文</option>-->
      <!--              </select>-->
      <!--            </div>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <i class='bx bx-user'></i>-->
      <!--            <a href="login.html">Login</a>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </div>-->
    </div>
  </div>
</div>

<div class="navbar-area">
  <div class="main-responsive-nav">
    <div class="container">
      <div class="main-responsive-menu">
        <div class="logo">
          <a href="#">
            <img src="assets/img/logo_unews.png" alt="image">
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="main-navbar">
    <div class="container">
      <nav class="navbar navbar-expand-md navbar-light">
        <a class="navbar-brand" routerLink="/">
          <img src="assets/img/logo_unews.png" alt="image">
        </a>
        <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
          <ul class="navbar-nav">

            <li routerLinkActive="active" class="nav-item ">
              <a routerLink="/accueil" class="nav-link">
               Accueil
              </a>
            </li>

            <li routerLinkActive="active"  class="nav-item ">
              <a routerLink="/categorie/actualités" class="nav-link">
                Actualites
                <i class='bx bx-chevron-down'></i>
              </a>
              <ul class="dropdown-menu">
                <li routerLinkActive="active" class="nav-item ">
                  <a routerLink="/categorie/internationale" class="nav-link">
                 Internationale
                  </a>
                </li>
                <li routerLinkActive="active" class="nav-item ">
                  <a routerLink="/categorie/économie" class="nav-link">
                   Economie
                  </a>
                </li>
                <li routerLinkActive="active" class="nav-item ">
                  <a routerLink="/categorie/technologie" class="nav-link">
                   Technologie
                  </a>
                </li>
                <li routerLinkActive="active" class="nav-item ">
                  <a routerLink="/categorie/sport" class="nav-link">
                   Sport
                  </a>
                </li>
                <li routerLinkActive="active" class="nav-item ">
                  <a routerLink="/categorie/diaspora" class="nav-link">
                   Diaspora
                  </a>
                </li>

              </ul>
            </li>

            <li routerLinkActive="active" class="nav-item ">
              <a routerLink="/categorie/circulation" class="nav-link">
                Circulation
              </a>
            </li>



            <li class="nav-item " routerLinkActive="active">
              <a href="javascript:void(0)" class="nav-link">
                Environnement
                <i class='bx bx-chevron-down'></i>
              </a>
              <ul class="dropdown-menu">
                <li routerLinkActive="active" class="nav-item ">
                  <a routerLink="/categorie/météo" class="nav-link">
                  Metéo
                  </a>
                </li>
                <li routerLinkActive="active" class="nav-item ">
                  <a routerLink="/categorie/catastrophes naturelles" class="nav-link">
                    Catastrophes Naturelles
                  </a>
                </li>
              </ul>
            </li>

            <li class="nav-item " routerLinkActive="active">
              <a href="javascript:void(0)" class="nav-link">
                Divertissement
                <i class='bx bx-chevron-down'></i>
              </a>
              <ul class="dropdown-menu">
                <li routerLinkActive="active" class="nav-item ">
                  <a routerLink="/categorie/loisir" class="nav-link">
                    Loisir
                  </a>
                </li>
                <li routerLinkActive="active" class="nav-item ">
                  <a routerLink="/categorie/humour" class="nav-link">
                    Humour
                  </a>
                </li>
              </ul>
            </li>
          </ul>

          <!--          <ul class="navbar-nav" *ngFor="let cat of categorie">-->
          <!--            <li routerLinkActive="active" class="nav-item ">-->
          <!--              <a routerLink="/{{ cat.nom }}" class="nav-link ">-->
          <!--                {{ cat.nom }}-->
          <!--              </a>-->
          <!--            </li>-->

          <!--          </ul>-->
          <div class="others-options d-flex align-items-center">
            <div class="option-item">
              <form class="search-box">
                <input type="text" class="form-control" placeholder="Search for..">
                <button type="submit"><i class='bx bx-search'></i></button>
              </form>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <div class="others-option-for-responsive">
    <div class="container">
      <div class="dot-menu">
        <div class="inner">
          <div class="circle circle-one"></div>
          <div class="circle circle-two"></div>
          <div class="circle circle-three"></div>
        </div>
      </div>
      <div class="container">
        <div class="option-inner">
          <div class="others-options d-flex align-items-center">
            <div class="option-item">
              <form class="search-box">
                <input type="text" class="form-control" placeholder="Search for..">
                <button type="submit"><i class='bx bx-search'></i></button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
