<div *ngFor="let articles  of Articles">
  <div class="single-business-news">
    <div class="row align-items-center">
      <div class="col-lg-4">
        <div class="business-news-image">
          <a routerLink="/{{articles.slug_url}}">
            <img src="{{ articles.image }}" alt="image" class="card-img img-thumbnail">
          </a>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="business-news-content-box">
          <span>{{ articles.categorie }}</span>
          <h3>
            <a routerLink="/{{articles.slug_url}}">{{ articles.titre }}</a>
          </h3>
          <p>{{ articles.description }}</p>
          <div class="date">{{ articles.auteur }} | {{ articles.date }}</div>
        </div>
      </div>
    </div>
  </div>

</div>
