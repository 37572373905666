
  <aside class="widget-area">
    <section class="widget widget_stay_connected">
      <h3 class="widget-title">Rester connecté</h3>
      <ul class="stay-connected-list">
        <li>
          <a href="#">
            <i class='bx bxl-facebook'></i>
            120,345 Fans
          </a>
        </li>
        <li>
          <a href="#" class="twitter">
            <i class='bx bxl-twitter'></i>
            25,321 Followers
          </a>
        </li>
        <li>
          <a href="#" class="linkedin">
            <i class='bx bxl-linkedin'></i>
            7,519 Connect
          </a>
        </li>
        <li>
          <a href="#" class="youtube">
            <i class='bx bxl-youtube'></i>
            101,545 Subscribers
          </a>
        </li>
        <li>
          <a href="#" class="instagram">
            <i class='bx bxl-instagram'></i>
            10,129 Followers
          </a>
        </li>
        <li>
          <a href="#" class="wifi">
            <i class='bx bx-wifi'></i>
            952 Subscribers
          </a>
        </li>
      </ul>
    </section>
    <section>
      <h2>Categorie</h2>

      <ul *ngFor="let cat of categorie">
        <li> <span class="fa mfp-arrow-right"></span>
          <a routerLink="/{{cat.nom | lowercase}}">
            {{ cat.nom }}
            <span pill="true" primary="true" class="badge bg-primary">{{ cat.totalArticles }}</span>
          </a>
        </li>
      </ul>
    </section>
    <section class="widget widget_latest_news_thumb">
      <h3 class="widget-title">Dernières nouvelles</h3>
      <div *ngFor="let r1 of recent">
        <article class="item mt-1">
          <a routerLink="/{{r1.slugUrl}}" class="thumb">
            <img src="{{r1.image}}" alt="image"  class="fullimage cover img-thumbnail" role="img">
          </a>
          <div class="info">
            <h4 class="title usmall"><a routerLink="/{{r1.slugUrl}}">{{r1.titre}}</a></h4>
            <span>{{r1.auteur}} | {{r1.date}}</span>
          </div>
        </article>

      </div>
    </section>

  </aside>
