import { Component, OnInit } from '@angular/core';
import {Categorie} from '../../../Models/categorie.model';
import {CategorieService} from '../../../Service/categorie.service';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  categorie?: Categorie[];
  constructor( private categorieService: CategorieService) { }

  ngOnInit(): void {
    this.getCategories();
  }
  getCategories(): void {
    this.categorieService.getAll()
      .subscribe(
        (data) => {

          this.categorie = data;
        },
        (error) => {
          console.log(error);
        });
  }
}
