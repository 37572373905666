import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './layout/home/home.component';
import {ErreurComponent} from './layout/erreur/erreur.component';
import {SingleArticleComponent} from './layout/single-article/single-article.component';
import {CategorieComponent} from './layout/categorie/categorie.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'accueil', component: HomeComponent},
  {path: ':slug', component: SingleArticleComponent},
  {path: 'categorie/:categorie', component: CategorieComponent},
  {path: 'accueil', redirectTo: '', pathMatch: 'full'},
  {path: '**', component: ErreurComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
