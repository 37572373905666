<!--<section class="footer-area pt-100 pb-70">-->
<!--  <div class="container">-->
<!--    <div class="row">-->
<!--      <div class="col-lg-3 col-md-6">-->
<!--        <div class="single-footer-widget">-->
<!--          <a href="#">-->
<!--            <img src="assets/img/logo-3.png" alt="image">-->
<!--          </a>-->
<!--          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut-->
<!--            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco-->
<!--            laboris nisi ut aliquip ex ea commodo consequat.</p>-->
<!--          <ul class="social">-->
<!--            <li>-->
<!--              <a href="#" class="facebook" target="_blank">-->
<!--                <i class='bx bxl-facebook'></i>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="#" class="twitter" target="_blank">-->
<!--                <i class='bx bxl-instagram'></i>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="#" class="pinterest" target="_blank">-->
<!--                <i class='bx bxl-linkedin'></i>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="#" class="linkedin" target="_blank">-->
<!--                <i class='bx bxl-twitter'></i>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="#" class="linkedin" target="_blank">-->
<!--                <i class='bx bxl-youtube'></i>-->
<!--              </a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-lg-3 col-md-6">-->
<!--        <div class="single-footer-widget">-->
<!--          <h2>Recent post</h2>-->
<!--          <div class="post-content">-->
<!--            <div class="row align-items-center">-->
<!--              <div class="col-md-4">-->
<!--                <div class="post-image">-->
<!--                  <a href="#">-->
<!--                    <img src="assets/img/recent-post/recent-post-1.jpg" alt="image">-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-md-8">-->
<!--                <h4>-->
<!--                  <a href="#">The match of the volleyball full of excitement</a>-->
<!--                </h4>-->
<!--                <span>28 Sep 2021</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="post-content">-->
<!--            <div class="row align-items-center">-->
<!--              <div class="col-md-4">-->
<!--                <div class="post-image">-->
<!--                  <a href="#">-->
<!--                    <img src="assets/img/recent-post/recent-post-2.jpg" alt="image">-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-md-8">-->
<!--                <h4>-->
<!--                  <a href="#">The match of the volleyball full of excitement</a>-->
<!--                </h4>-->
<!--                <span>28 Sep 2021</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="post-content">-->
<!--            <div class="row align-items-center">-->
<!--              <div class="col-md-4">-->
<!--                <div class="post-image">-->
<!--                  <a href="#">-->
<!--                    <img src="assets/img/recent-post/recent-post-3.jpg" alt="image">-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-md-8">-->
<!--                <h4>-->
<!--                  <a href="#">The match of the volleyball full of excitement</a>-->
<!--                </h4>-->
<!--                <span>28 Sep 2021</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-lg-3 col-md-6">-->
<!--        <div class="single-footer-widget">-->
<!--          <h2>Useful links</h2>-->
<!--          <ul class="useful-links-list">-->
<!--            <li>-->
<!--              <a href="#">Contact us</a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="#">News</a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="#">Privacy & policy</a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="#">Terms & conditions</a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="#">Affilate ads</a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="#">Business</a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="#">Technology</a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="#">Entertainment</a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="#">Politics</a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-lg-3 col-md-6">-->
<!--        <div class="single-footer-widget">-->
<!--          <h2>Subscribe</h2>-->
<!--          <div class="widget-subscribe-content">-->
<!--            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut-->
<!--              labore et dolore magna aliqua.</p>-->
<!--            <form class="newsletter-form">-->
<!--              <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL"-->
<!--                     required>-->
<!--              <button type="submit">Subscribe</button>-->
<!--            </form>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->


<div class="copyright-area">
  <div class="container">
    <div class="copyright-area-content">
      <p>
         © 2021 <a href="javascript:void(0)" >Unews</a>.Tous droits  réservés.
      </p>
    </div>
  </div>
</div>


<div class="go-top">
  <i class='bx bx-up-arrow-alt'></i>
</div>
