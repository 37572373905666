<app-menu></app-menu>
<app-home-recent></app-home-recent>
<section class="default-news-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <app-home-list></app-home-list>
      </div>
      <div class="col-lg-4">
        <app-sidebar></app-sidebar>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
