import { Component, OnInit } from '@angular/core';
import {ArticleService} from '../../../Service/article.service';
import {Article} from '../../../Models/article.model';

@Component({
  selector: 'app-home-recent',
  templateUrl: './home-recent.component.html',
  styleUrls: ['./home-recent.component.css']
})
export class HomeRecentComponent implements OnInit {
  Articles: Article[];

  constructor(private articleService: ArticleService) { }

  ngOnInit(): void {
    this.getArticles();
  }
  getArticles(): void {
    this.articleService.getRecent()
      .subscribe(
        (data) => {

          this.Articles = data;
        },
        (error) => {
          console.log(error);
        });
  }

}
