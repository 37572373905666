<app-menu></app-menu>
<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>{{categorie}}</h2>
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{categorie | titlecase}}</li>
      </ul>
    </div>
  </div>
</div>


<section class="default-news-area ptb-50">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <div *ngFor="let a1  of article">
          <div class="single-tech-inner-news">
            <div class="row align-items-center">
              <div class="col-lg-4">
                <div class="tech-news-image">
                  <a href="javascript:void(0)">
                    <img src="{{a1.image}}" alt="image">
                  </a>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="tech-news-content mt-0">
                  <span>{{a1.categorie}}</span>
                  <h3>
                    <a href="#">{{a1.titre}}</a>
                  </h3>
                  <p>{{a1.description}} </p>
                  <p>{{a1.auteur}} | {{a1.date | date}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <app-sidebar></app-sidebar>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
