
<app-menu></app-menu>
<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>404 error</h2>
      <ul>
        <li><a href="index.html">Home</a></li>
        <li>404 error</li>
      </ul>
    </div>
  </div>
</div>


<section class="error-area ptb-50">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="error-content">
          <img src="assets/img/404-error.png" alt="error">
          <h3>Error 404 : page not found</h3>
          <p>The page you are looking for might have been removed had its name changed or is temporarily
            unavailable.</p>
          <a href="index.html" class="default-btn">
            Go to home
          </a>
        </div>
      </div>
    </div>
  </div>
</section>


<app-footer></app-footer>
