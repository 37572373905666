import { Component, OnInit } from '@angular/core';
import {Categorie} from '../../../Models/categorie.model';
import {CategorieService} from '../../../Service/categorie.service';
import {Article} from '../../../Models/article.model';
import {ArticleService} from '../../../Service/article.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  categorie?: Categorie[];
  recent?: Article[];
  constructor(private articleService: ArticleService, private categorieService: CategorieService) { }

  ngOnInit(): void {
    this.getCategories();
    this.getRecents();
  }
  getCategories(): void {
    this.categorieService.getAll()
      .subscribe(
        (data) => {

          this.categorie = data;
        },
        (error) => {
         console.log(error);
        });
  }
  getRecents(): void {
    this.articleService.getRecent()
      .subscribe(
        (data) => {

          this.recent = data;
        },
        (error) => {
          console.log(error);
        });
  }
}
