import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ArticleService} from '../../Service/article.service';
import {Article} from '../../Models/article.model';

@Component({
  selector: 'app-categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./categorie.component.css']
})
export class CategorieComponent implements OnInit {
  article?: Article[];

  categorie?: '';

  constructor(private articleService: ArticleService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {

    this.route.params.subscribe(routeparams => {
      this.categorie = routeparams.categorie;
      this.getArticles(routeparams.categorie);
    });

  }

  getArticles(categorie: any): void {
    this.articleService.getHot()
      .subscribe(
        (data) => {

          this.article = data.filter((s) => {
            return s.categorie === categorie;
          });
        },
        (error) => {
          console.log(error);
        });
  }


}
