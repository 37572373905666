import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ArticleService} from '../../Service/article.service';
import {Article} from '../../Models/article.model';

@Component({
  selector: 'app-single-article',
  templateUrl: './single-article.component.html',
  styleUrls: ['./single-article.component.css']
})
export class SingleArticleComponent implements OnInit {
  article?: Article;
  slug?: '';

  constructor(private articleService: ArticleService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(routeparams => {
      this.slug = routeparams.slug;
      this.getArticles(routeparams.slug);
    });
  }

  getArticles(slug: any): void {
    this.articleService.getAll()
      .subscribe(
        (data) => {
          this.article = data.find((s) => {
            return s.slug_url === slug;
          });
        },
        (error) => {
          console.log(error);
        });
  }


}
