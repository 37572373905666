import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Categorie} from '../Models/categorie.model';
const baseUrl = 'https://unews.intello.ht/api/liste-categorie';
@Injectable({
  providedIn: 'root'
})
export class CategorieService {

  constructor(private http: HttpClient) {
  }
  getAll(): Observable<Categorie[]> {
    return this.http.get<Categorie[]>(`${baseUrl}`);
  }
  // getOne(code: any): Observable<Categorie> {
  //   return this.http.get<Categorie>(`${baseUrl}/${code}`);
  // }
}
