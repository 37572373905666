import {Component, OnInit} from '@angular/core';
import {ArticleService} from '../../../Service/article.service';
import {Article} from '../../../Models/article.model';

@Component({
  selector: 'app-home-list',
  templateUrl: './home-list.component.html',
  styleUrls: ['./home-list.component.css']
})
export class HomeListComponent implements OnInit {
  Articles: Article[];

  constructor(private articleService: ArticleService) {
  }

  ngOnInit(): void {
    this.getArticles();
  }

  getArticles(): void {
    this.articleService.getHot()
      .subscribe(
        (data) => {

          this.Articles = data;
        },
        (error) => {
          console.log(error);
        });
  }



}
