import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Article} from '../Models/article.model';

const baseUrl = 'https://unews.intello.ht/api';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private http: HttpClient) {
  }

  getAll(): Observable<Article[]> {
    return this.http.get<Article[]>(`${baseUrl}/liste-article`);
  }

  getRecent(): Observable<Article[]> {
    return this.http.get<Article[]>(`${baseUrl}/liste-article?recents`);
  }

  getHot(): Observable<Article[]> {
    return this.http.get<Article[]>(`${baseUrl}/liste-article?hot`);
  }

  getOne(id: any): Observable<Article> {
    return this.http.get<Article>(`${baseUrl}/article-${id}`);
  }
}
